.vssue {
  padding: 0 !important;
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}

.vssue .vssue-new-comment .vssue-new-comment-input {
  [theme=dark] & {
    color: $global-font-color-dark;
    background-color: transparent;
  }
}

.vssue .vssue-new-comment .vssue-new-comment-input:focus {
  [theme=dark] & {
    background-color: transparent;
  }
}
.vssue .vssue-pagination .vssue-pagination-select {
  color: $global-font-color-dark;
  border-color: $global-font-color-dark;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg class='icon' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle/%3E%3C/defs%3E%3Cpath d='M676.395 432.896a21.333 21.333 0 0 0-30.166 0L511.061 568.021 377.728 434.645a21.333 21.333 0 0 0-30.165 30.166l148.394 148.48a21.419 21.419 0 0 0 30.208 0l150.23-150.187a21.333 21.333 0 0 0 0-30.208' style='fill:%23a9a9b3' /%3E%3C/svg%3E");
  [theme=dark] & {
    background-color: $global-background-card-color-dark;
  }
}

.markdown-body {
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}

.markdown-body .pl-s .pl-s1,
.markdown-body .pl-smi {
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}

.markdown-body .pl-mi {
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}

.markdown-body .pl-mb {
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}

.markdown-body .blob-code-inner {
  [theme=dark] & {
    color: $global-font-color-dark;
  }
}
