.post-footer {
  margin-top: 1rem;
  padding-bottom: 1rem;

  .post-info {
    border-top: 1px solid $global-border-color;
    border-bottom: 1px solid $global-border-color;
    padding: 1rem 0 .5rem;

    [theme=dark] & {
      border-color: $global-border-color-dark;
    }

    .post-info-share {
      a * {
        font-size: 1.5rem;
        vertical-align: text-bottom;
        margin: 0 5px 5px 0;
      }
    }

    .post-info-tag {
      font-size: .875rem;
      a {
        color: $global-font-secondary-color;
        display: inline-block;
        line-height: 26px;
        padding: 0 10px;
        position: relative;
        margin: 0 5px 5px 0;
        text-decoration: none;
        -webkit-transition: color 0.2s;
        @include border-radius(.25rem);
        border: 1px solid;
        &:hover {
          background-color: $global-background-color;
          color: $global-link-hover-color;
        }
        [theme=dark] & {
          color: $global-font-secondary-color-dark;
          &:hover {
            background-color: $global-background-color-dark;
            color: $global-link-hover-color-dark;
          }
        }
      }
    }

    .post-info-line {
      padding-bottom: .5rem;
      .post-info-mod {
        font-size: 0.8em;
        color: $global-font-secondary-color;

        [theme=dark] & {
          color: $global-font-secondary-color-dark;
        }

        @include link(false, false);
      }
    }
  }

  .post-nav {
    padding-top: 1rem;
    &::before,
    &::after {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      content: ' ';
      display: table;
    }

    & a.prev,
    & a.next {
      font-size: 1rem;
      font-weight: 600;
      @include transition(all 0.3s ease-out);
    }

    & a.prev {
      float: left;
    }

    & a.prev:hover {
      @include transform(translateX(-4px));
    }

    & a.next {
      float: right;
    }

    & a.next:hover {
      @include transform(translateX(4px));
    }
  }
}
