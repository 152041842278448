.mermaid {
  width: 100%;
  margin: 3% auto;
  text-align: center;
  background-color: $code-background-color;
  padding: 1rem;

  & > svg {
    max-width: 100%;
  }

  div.mermaidTooltip {
    z-index: 50;
  }
}
